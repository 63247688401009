import React, { useState } from "react";

import { FaCheck, FaGithub, IconName } from "react-icons/fa";
import { Container, Row, Col } from "react-grid-system";
import Carousel, { Modal, ModalGateway } from "react-images";
// Components
import GoBack from "../../components/common/GoBack";
//import main_img from "../../../public/assets/images/projects/ThymeWebpage.png";

const images = [
  {
    src: "/assets/images/projects/ThymeWebpage.png",
  },
];

const CorgiCoinPage = () => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const toggleLightbox = (index) => {
    setSelectedIndex(index);
    setLightboxIsOpen(!lightboxIsOpen);
  };

  return (
    <section className="section section-single-post section-single-post-1">
      <div className="display-spacing">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="max-w-768 div-center">
                <div className="post">
                  <div className="post-head">
                    <div class="post-info">
                      <GoBack />
                      <h1>Corgi Crew Coin</h1>
                    </div>
                  </div>
                  {/* Need to add button to Github
                Write a function to only show the button if there is a github linked provided */}
                  <div className="post-body">
                    <div className="post-content">
                      <p>
                        <b>Company:</b> <span> Corgi Crew Coin</span>
                      </p>
                      <p>
                        <b>Role:</b>
                        <span> Founder, CEO, Software Engineer, Web Developer</span>
                      </p>
                      <p>
                        <b>Technologies:</b>
                      </p>
                      <ul class="el-list-check">
                        <li>Solidity, JavaScript</li>
                        <li>Binance Smart Chain</li>
                      </ul>
                      <p>
                      Currently working on a deflationary cryptocurrency token that will be deployed on the Binance smart chain network. 
                      I am developing this token using Solidity and JavaScript.  
                      Right now there is a smart contract deployed on the Binance smart chain testnet where I am finalizing the redistribution of a percentage of transactions to all token holders. 
                      Once that is finished I will finish the website with the graphics made by a contracted graphic designer for the coin and launch it once the liquidity goals are reached
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <Row>
                <Col style={{ marginTop: "50px", marginLeft: "20px" }}>
                  {/* <iframe
                    width="600px"
                    height="360px"
                    src="https://www.youtube.com/embed/ziS73p9g4AE"
                  /> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={toggleLightbox}>
              <Carousel currentIndex={selectedIndex} views={images} />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </section>
  );
};

export default CorgiCoinPage;
