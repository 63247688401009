import React, { useState } from "react";

import { FaCheck, FaGithub, IconName } from "react-icons/fa";
import { Container, Row, Col } from "react-grid-system";
import Carousel, { Modal, ModalGateway } from "react-images";
// Components
import GoBack from "../../components/common/GoBack";
//import main_img from "../../../public/assets/images/projects/ThymeWebpage.png";

const images = [
  {
    src: "/assets/images/projects/ThymeWebpage.png",
  },
];

const ThymePage = () => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const toggleLightbox = (index) => {
    setSelectedIndex(index);
    setLightboxIsOpen(!lightboxIsOpen);
  };

  return (
    <section className="section section-single-post section-single-post-1">
      <div className="display-spacing">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="max-w-768 div-center">
                <div className="post">
                  <div className="post-head">
                    <div class="post-info">
                      <GoBack />
                      <h1>Thyme Webpage</h1>
                    </div>
                  </div>
                  {/* Need to add button to Github
                Write a function to only show the button if there is a github linked provided */}
                  <div>
                    <button
                      className="button button-lg button-primary"
                      style={{ color: "white" }}
                      onClick={() =>
                        window.open(
                          "https://www.github.com/StephenKoy/Thyme-Webpage",
                          "_blank"
                        )
                      }
                    >
                      <span className="wave"></span>
                      <FaGithub className="icon" />
                    </button>
                  </div>
                  <div className="post-body">
                    <div className="post-content">
                      <p>
                        <b>Company:</b> <span> USC - Web Technologies</span>
                      </p>
                      <p>
                        <b>Technologies:</b>
                      </p>
                      <ul class="el-list-check">
                        <li>
                          {/* <FaCheck className="icon" /> */}
                          HTML5
                        </li>
                        <li>
                          {/* <FaCheck className="icon" /> */}
                          CSS3
                        </li>
                      </ul>
                      <p>
                        This is a webpage made for USC CSCI 571: Web
                        Technologies. It was written using HTML5 and CSS3. It's
                        an HTML website with CSS that uses single page links to
                        navigate the main page and uses a parallax scrolling
                        effect.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <Row>
                <Col style={{ marginTop: "50px", marginLeft: "20px" }}>
                  {/* <iframe
                    width="600px"
                    height="360px"
                    src="https://www.youtube.com/embed/ziS73p9g4AE"
                  /> */}

                  <video width="600px" height="360px" controls>
                    <source
                      src="/assets/videos/hw3movie.mp4"
                      type="video/mp4"
                    />
                  </video>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={toggleLightbox}>
              <Carousel currentIndex={selectedIndex} views={images} />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </section>
  );
};

export default ThymePage;
