import React, { useState } from "react";

import { FaCheck, FaGithub, IconName } from "react-icons/fa";
import { Container, Row, Col } from "react-grid-system";
import Carousel, { Modal, ModalGateway } from "react-images";
// Components
import GoBack from "../../components/common/GoBack";
//import main_img from "../../../public/assets/images/projects/ThymeWebpage.png";

const images = [
  {
    src: "/assets/images/projects/ThymeWebpage.png",
  },
];

const TravelEntertainmentWebPage = () => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const toggleLightbox = (index) => {
    setSelectedIndex(index);
    setLightboxIsOpen(!lightboxIsOpen);
  };

  return (
    <section className="section section-single-post section-single-post-1">
      <div className="display-spacing">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="max-w-768 div-center">
                <div className="post">
                  <div className="post-head">
                    <div class="post-info">
                      <GoBack />
                      <h1>Travel and Entertainment Search: Web Application</h1>
                    </div>
                  </div>
                  {/* Need to add button to Github
                Write a function to only show the button if there is a github linked provided */}
                  {/* <div>
                    <button
                      onClick={() =>
                        window.open(
                          "https://www.github.com/StephenKoy/Thyme-Webpage",
                          "_blank"
                        )
                      }
                    >
                      <FaGithub className="icon" />
                    </button>
                  </div> */}
                  <div className="post-body">
                    <div className="post-content">
                      <p>
                        <b>Organization:</b> <span> USC - Web Technologies</span>
                      </p>
                      <p>
                        <b>Technologies:</b>
                      </p>
                      <ul class="el-list-check">
                        <li>JavaScript, Angular, JQuery, Bootstrap, Node.js, JSON</li>
                        <li>PHP, HTML5, CSS3</li>
                      </ul>
                      <p>
                        Built a travel and entertainment place search webpage that is mobile friendly to
                        explore local neighborhoods based on the postal code
                        entered using a combination of Angular, Bootstrap, Node.js, Google
                        Maps API, Yelp API, Twitter API, and Google App Engine.
                        The project was deployed on the Google Cloud Platform
                        and Allowed users to browse place details and street
                        view, navigate with Google Maps, create a favorites list
                        and share place to social network. There was an earlier
                        version of this web application that I made with
                        JavaScript, PHP and JQuery.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <Row>
                <Col style={{ marginTop: "50px", marginLeft: "20px" }}>
                  {/* <iframe
                    width="600px"
                    height="360px"
                    src="https://www.youtube.com/embed/ziS73p9g4AE"
                  /> */}
                  <video width="600px" height="360px" controls>
                    <source
                      src="/assets/videos/CSCI571_Spring2018_HW8_Desktop.mp4"
                      type="video/mp4"
                    />
                  </video>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={toggleLightbox}>
              <Carousel currentIndex={selectedIndex} views={images} />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </section>
  );
};

export default TravelEntertainmentWebPage;
