import React, { useState } from "react";

import { FaCheck, FaGithub, IconName } from "react-icons/fa";
import { Container, Row, Col } from "react-grid-system";
import Carousel, { Modal, ModalGateway } from "react-images";
// Components
import GoBack from "../../components/common/GoBack";
//import main_img from "../../../public/assets/images/projects/ThymeWebpage.png";

const images = [
  {
    src: "/assets/images/projects/ThymeWebpage.png",
  },
];

const BitsMoneyPage = () => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const toggleLightbox = (index) => {
    setSelectedIndex(index);
    setLightboxIsOpen(!lightboxIsOpen);
  };

  return (
    <section className="section section-single-post section-single-post-1">
      <div className="display-spacing">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="max-w-768 div-center">
                <div className="post">
                  <div className="post-head">
                    <div class="post-info">
                      <GoBack />
                      <h1>Bits Money Budgeting App</h1>
                    </div>
                  </div>
                  {/* Need to add button to Github
                Write a function to only show the button if there is a github linked provided */}
                  <div>
                    <a href="/assets/res/BitsMoneyPresentation.pptx">
                      <button
                        className="button button-lg button-primary"
                        style={{ color: "white" }}
                      >
                        <span className="wave"></span>
                        <span>Slides</span>
                      </button>
                    </a>
                  </div>
                  <div className="post-body">
                    <div className="post-content">
                      <p>
                        <b>Organization:</b> <span> USC - UX Strategy</span>
                      </p>
                      <p>
                        <b>Technologies:</b>
                      </p>
                      <ul class="el-list-check">
                        <li>
                          Sketch software, LEAN, UX Strategy, Facebook Marketing
                        </li>
                      </ul>
                      <p>
                        Designed a concept for a budgeting / payments app that
                        enables users to manage recurrent payments. I ran the
                        idea through competitive analysis, created personas,
                        conducted user studies, created mock ups using Sketch
                        and a Facebook marketing campaigns; Where I achieved
                        over 10% conversion over multiple iterations.  
                        Truebill an app similar to mine received 15 million dollars in seed funding by YouTube co-founder Jawed Karim, 
                        8 months after I finished my strategy product.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <Row>
                <Col style={{ marginTop: "50px", marginLeft: "20px" }}>
                  <img
                    style={{ width: "600px", height: "500px" }}
                    src="/assets/images/projects/bits_ui.png"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={toggleLightbox}>
              <Carousel currentIndex={selectedIndex} views={images} />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </section>
  );
};

export default BitsMoneyPage;
