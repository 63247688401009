import React, { useState } from "react";

import { FaCheck, FaGithub, IconName } from "react-icons/fa";
import { Container, Row, Col } from "react-grid-system";
import Carousel, { Modal, ModalGateway } from "react-images";
// Components
import GoBack from "../../components/common/GoBack";
//import main_img from "../../../public/assets/images/projects/ThymeWebpage.png";

const images = [
  {
    src: "/assets/images/projects/ThymeWebpage.png",
  },
];

const F35Page = () => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const toggleLightbox = (index) => {
    setSelectedIndex(index);
    setLightboxIsOpen(!lightboxIsOpen);
  };

  return (
    <section className="section section-single-post section-single-post-1">
      <div className="display-spacing">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="max-w-768 div-center">
                <div className="post">
                  <div className="post-head">
                    <div class="post-info">
                      <GoBack />
                      <h1>F-35: Prognostics Health Management</h1>
                    </div>
                  </div>
                  <div className="post-body">
                    <div className="post-content">
                      <p>
                        <b>Company:</b> <span> Northrop Grumman</span>
                      </p>
                      <p>
                        <b>Role:</b>
                        <span> Software Engineer</span>
                      </p>
                      <p>
                        <b>Technologies:</b>
                      </p>
                      <ul class="el-list-check">
                        <li>C++, Python, SWAT</li>
                        <li>IBM Rational Rhapsody, Linux, Serena Dimensions</li>
                      </ul>
                      <p>
                      The F-35 Prognostic Health Maintenance Software is an embedded software on the F-35 fighter jet that runs on it's RTOS. The heads up display allows the pilot to be able to view statuses and receive notifications of and about in-flight systems (i.e. overheating, subsystem failure, ammunition, and more). 
                      I am the point of contact and lead for the DAS, EODAS, EOTS, RADAR, P5CTS, and ICP subsystems.  I do all the development, integration, testing, system object integration testing, as well as develop tools.  I have been tasked with most of the new TR3 system integration, developing new system objects for the latest hardware refresh.
                      Since some of this information is Classified I can only say that the we use AGILE and the development generally consists of signal processing, new capability implementation, and software migration.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <Row>
                <Col style={{ marginTop: "50px", marginLeft: "20px" }}>
                  {/* <iframe
                    width="600px"
                    height="360px"
                    src="https://www.youtube.com/embed/ziS73p9g4AE"
                  /> */}
                  <img src="/assets/images/projects/F35.jpg" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={toggleLightbox}>
              <Carousel currentIndex={selectedIndex} views={images} />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </section>
  );
};

export default F35Page;
