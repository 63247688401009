import React, { useEffect } from "react";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
// Sections
import Home from "./Home";
import Header from "./Header";

// Components
import Helmet from "../components/common/Helmet";
import Switch from "../components/common/Switch";
import RedirectAs404 from "../components/common/RedirectAs404";
import HomePage from "./Home";
import AboutMePage from "./AboutMe";
import Projects from "./Projects";
import PhotographyPage from "./Photography";
import Contact from "./Contact";
import ThymePage from "./project_item/Thyme";
import F35Page from "./project_item/F35";
import BitsMoneyPage from "./project_item/BitsMoney";
import CorgiCoinPage from "./project_item/CorgiCrewCoin";
import MissionPlanningPage from "./project_item/MissionPlanning";
import PersonalWebsitePage from "./project_item/PersonalWebsite";
import SpaceForcePage from "./project_item/SpaceForceFlyingSim";
import StockAppWebPage from "./project_item/StockAppWeb";
import StockAppAndroidPage from "./project_item/StockAppAndroid";
import TravelEntertainmentWebPage from "./project_item/TravelEntertainmentWeb";
import TravelEntertainmentAndroidPage from "./project_item/TravelEntertainmentAndroid";

const routes = [
    {
        path: "Home",
        component: <HomePage />,
    },
    {
        path: "AboutMe",
        component: <AboutMePage />,
    },
    {
        path: "Projects",
        component: <Projects />,
    },
    {
        path: "Photography",
        component: <PhotographyPage />,
    },
    {
        path: "Contact",
        component: <Contact />,
    },
];


const Index = () => {
    let { path } = useRouteMatch();

    useEffect(() => {
        document.documentElement.className = "skin-5";
        return () => {
            document.documentElement.className = "";
        };
    });
    return (
        <div>
            <Helmet title="Stephen Koy" />
            <Header />
            <Switch>
                <Route path="/" exact>
                    <Redirect to="/Home"/>
                </Route>
                <Route path="/Projects/BitsMoney" exact><BitsMoneyPage/></Route>
                <Route path="/Projects/CorgiCrewCoin" exact><CorgiCoinPage/></Route>
                <Route path="/Projects/F35" exact><F35Page/></Route>
                <Route path="/Projects/MissionPlanning" exact><MissionPlanningPage/></Route>
                <Route path="/Projects/PersonalWebsite" exact><PersonalWebsitePage/></Route>
                <Route path="/Projects/SpaceForceFlyingSim" exact><SpaceForcePage/></Route>
                <Route path="/Projects/StockAppAndroid" exact><StockAppAndroidPage/></Route>
                <Route path="/Projects/StockAppWeb" exact><StockAppWebPage/></Route>
                <Route path="/Projects/ThymeWebsite" exact><ThymePage /></Route>
                <Route path="/Projects/TravelEntertainmentAndroid" exact><TravelEntertainmentAndroidPage/></Route>
                <Route path="/Projects/TravelEntertainmentWeb" exact><TravelEntertainmentWebPage/></Route>
                {/* <Route path="/Projects/TestProject"><TestProject/></Route> */}
                <Route path={path} exact>
                    <Redirect
                        to={{
                            pathname: `${path}/`.replace(/([^:])(\/\/+)/g, "$1/"),
                        }}
                    />
                </Route>
                {routes.map((item, index) => (
                    <Route key={index} path={`${path}${item.path}`} exact>
                        {item.component}
                    </Route>
                ))}
                <Route component={RedirectAs404} />
            </Switch>
        </div>
    );
}

export default Index;
