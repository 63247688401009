import React from "react";
import { Route, Switch } from "react-router-dom";

import RedirectAs404 from "./components/common/RedirectAs404";
import HomePage from "./pages";

function App() {
  return (
    <Switch>
      <Route to="/home" component={HomePage} />
      <Route component={RedirectAs404} />
    </Switch>
  );
}

export default App;
