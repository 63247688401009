import React, { useState } from "react";

import { FaCheck, FaGithub, IconName } from "react-icons/fa";
import { Container, Row, Col } from "react-grid-system";
import Carousel, { Modal, ModalGateway } from "react-images";
// Components
import GoBack from "../../components/common/GoBack";
//import main_img from "../../../public/assets/images/projects/ThymeWebpage.png";

const images = [
  {
    src: "/assets/images/projects/ThymeWebpage.png",
  },
];

const MissionPlanningPage = () => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const toggleLightbox = (index) => {
    setSelectedIndex(index);
    setLightboxIsOpen(!lightboxIsOpen);
  };

  return (
    <section className="section section-single-post section-single-post-1">
      <div className="display-spacing">
        <Container>
          <Row>
            <Col lg={9}>
              <div className="max-w-768 div-center">
                <div className="post">
                  <div className="post-head">
                    <div class="post-info">
                      <GoBack />
                      <h1>Mission Planning Systems</h1>
                    </div>
                  </div>
                  <div className="post-body">
                    <div className="post-content">
                      <p>
                        <b>Company:</b>{" "}
                        <span> Department of the Air Force</span>
                      </p>
                      <p>
                        <b>Role:</b>
                        <span> Computer Scientist</span>
                      </p>
                      <p>
                        <b>Technologies:</b>
                      </p>
                      <ul class="el-list-check">
                        <li>C++, C#, Xamarin</li>
                        <li>Visual Studio, Team Foundation Server</li>
                      </ul>
                      <p>
                        Flight Performance Model software/systems engineer,
                        where I was responsible for the data development,
                        design, software development, testing of many different
                        aircraft using C++ and integrated them with the FPM
                        Library, Joint Mission Planning System, and Portable
                        Flight Planning System to aid in mission planning
                        processes.
                      </p>
                      <p>
                        I applied my skill by leading nine different projects
                        through the software development lifecycle.
                        Communicating and collaborating with the customer and
                        multiple organizations to acquire product requirements,
                        as well as achieve excellent, design, development,
                        testing, and delivery of products using the Capability
                        Maturity Model Integration at levels 3-5.
                      </p>
                      <p>
                        Deeply involved with internal testing members and communicating
                        with external testing organizations, saw projects
                        through requirements gathering, RRB/MRB, Development,
                        internal testing, engineering release, bug fixes, build
                        rerolls, and the FQT process, using in house tools for
                        preparation and final delivery of product documentation
                        and source code. One of the Lead Xamarin iOS Developers
                        for mobile device products and a tester for the TOLD-C
                        product which integrates the FPM modules for the end
                        user. Other duties include design document review,
                        regression testing, source code testing, flight manual
                        analysis, design and development of in-house tools and
                        training new hires.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={7}>
              <Row>
                <Col style={{ marginTop: "50px", marginLeft: "20px" }}>
                  {/* <iframe
                    width="600px"
                    height="360px"
                    src="https://www.youtube.com/embed/ziS73p9g4AE"
                  /> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={toggleLightbox}>
              <Carousel currentIndex={selectedIndex} views={images} />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </section>
  );
};

export default MissionPlanningPage;
