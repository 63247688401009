import React from "react";
import { Container } from "react-grid-system";
import Particles from "react-particles-js";
import TextLoop from "react-text-loop";

import logo from "../logo.svg";

const HomePage = () => {
  return (
    <section
      className="section section-hero section-hero-6 overlay-image"
      style={{
        backgroundImage: "url(/assets/images/photos/sadboi_cropped_new.png",
      }}
    >
      <div className="display-center">
        <Container>
          <div className="el-heading">
            <p className="el-icon">
              <span className="el-icon-title">
                Hi, I'm{" "}
                <em
                  style={{
                    fontStyle: "normal",
                    color: "#00b7b8",
                  }}
                >
                  Stephen
                </em>
              </span>
            </p>
            <h1>
              I'm{" "}
              <TextLoop>
                <span>a Software Engineer.</span>
                <span>a Product Strategist.</span>
                <span>a UI/UX Developer.</span>
                <span>an Actor.</span>
              </TextLoop>
              <br />
              Based in Los Angeles
            </h1>
            <button
              className="button button-lg button-primary"
              style={{ color: "white" }}
              onClick={() =>
                window.open("https://www.linkedin.com/in/stephenkoy/", "_blank")
              }>
              <span className="wave"></span>
              <span>LinkedIn</span>
            </button>
            <button
              className="button button-lg button-dark"
              onClick={() =>
                window.open("https://www.github.com/StephenKoy/", "_blank")
              }
            >
              <span className="text">Github</span>
            </button>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default HomePage;
