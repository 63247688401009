import React, { useState } from "react";

import { FaCheck, FaGithub, IconName } from "react-icons/fa";
import { Container, Row, Col } from "react-grid-system";
import Carousel, { Modal, ModalGateway } from "react-images";
// Components
import GoBack from "../../components/common/GoBack";
//import main_img from "../../../public/assets/images/projects/ThymeWebpage.png";

const images = [
  {
    src: "/assets/images/projects/ThymeWebpage.png",
  },
];

const TravelEntertainmentAndroidPage = () => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const toggleLightbox = (index) => {
    setSelectedIndex(index);
    setLightboxIsOpen(!lightboxIsOpen);
  };

  return (
    <section className="section section-single-post section-single-post-1">
      <div className="display-spacing">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="max-w-768 div-center">
                <div className="post">
                  <div className="post-head">
                    <div class="post-info">
                      <GoBack />
                      <h1>
                        Travel and Entertainment Search: Android Application
                      </h1>
                    </div>
                  </div>
                  {/* Need to add button to Github
                Write a function to only show the button if there is a github linked provided */}
                  <div>
                    <button
                      className="button button-lg button-primary"
                      style={{ color: "white" }}
                      onClick={() =>
                        window.open(
                          "https://github.com/Stephenkoy/Travel-and-Entertainment-Search-Android-App",
                          "_blank"
                        )
                      }
                    >
                      <span className="wave"></span>
                      <FaGithub className="icon" />
                    </button>
                  </div>
                  <div className="post-body">
                    <div className="post-content">
                      <p>
                        <b>Organization:</b>{" "}
                        <span> USC - Web Technologies</span>
                      </p>
                      <p>
                        <b>Technologies:</b>
                      </p>
                      <ul class="el-list-check">
                        <li>Java, Android, JSON</li>
                        <li>Android Studio</li>
                      </ul>
                      <p>
                        Based off of the travel and entertainment place search
                        web application, but for Android it explores local
                        neighborhoods based on the postal code entered using the
                        Google Maps API, Yelp API, twitter API, and Google App
                        Engine. The project was deployed on the Google Cloud
                        Platform and Allowed users to browse place details and
                        street view, navigate with Google Maps, create a
                        favorites list and share place to social network.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <Row>
                <Col style={{ marginTop: "50px", marginLeft: "20px" }}>
                  {/* <iframe
                    width="600px"
                    height="360px"
                    src="/assets/"
                  /> */}
                  <video width="600px" height="360px" controls>
                    <source
                      src="/assets/videos/CSCI_571_HW9_Android_Spring_2018.mp4"
                      type="video/mp4"
                    />
                  </video>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={toggleLightbox}>
              <Carousel currentIndex={selectedIndex} views={images} />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </section>
  );
};

export default TravelEntertainmentAndroidPage;
