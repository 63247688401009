import React, { useState } from "react";

import { FaCheck, FaGithub, IconName } from "react-icons/fa";
import { Container, Row, Col } from "react-grid-system";
import Carousel, { Modal, ModalGateway } from "react-images";
// Components
import GoBack from "../../components/common/GoBack";
//import main_img from "../../../public/assets/images/projects/ThymeWebpage.png";

const images = [
  {
    src: "/assets/images/projects/ThymeWebpage.png",
  },
];

const PersonalWebsitePage = () => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const toggleLightbox = (index) => {
    setSelectedIndex(index);
    setLightboxIsOpen(!lightboxIsOpen);
  };

  return (
    <section className="section section-single-post section-single-post-1">
      <div className="display-spacing">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="max-w-768 div-center">
                <div className="post">
                  <div className="post-head">
                    <div class="post-info">
                      <GoBack />
                      <h1>US Space Force Flying Simulator 2019</h1>
                    </div>
                  </div>
                  {/* Need to add button to Github
                Write a function to only show the button if there is a github linked provided */}
                  <div className="post-body">
                    <div className="post-content">
                      <p>
                        <b>Company:</b>
                        <span> USC - Augmented / Virtual Reality</span>
                      </p>
                      <p>
                        <b>Role:</b>
                        <span> Gameplay, Level Designer, UI/UX Developer</span>
                      </p>
                      <p>
                        <b>Technologies:</b>
                      </p>
                      <ul class="el-list-check">
                        <li>
                          {/* <FaCheck className="icon" /> */}
                          C#
                        </li>
                        <li>
                          {/* <FaCheck className="icon" /> */}
                          Unity3D, Oculus Rift
                        </li>
                      </ul>
                      <p>
                        US Space Force Flying Simulator 2019 is a VR flight
                        simulator game developed in Unity3D for the Oculus Rift.
                        The game was presented at the USC Games Expo in 2018. My
                        responisbilities were the tutorial level design and
                        implementation, User Interface, and the cockpit map. Our
                        biggest challenge was trying to solve the issue of
                        motion sickness while playing VR games. After many
                        iterations we settled on adding bars on the cockpit
                        window to have a reference point.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <Row>
                <Col style={{ marginTop: "50px", marginLeft: "20px" }}>
                  <iframe
                    width="600px"
                    height="360px"
                    src="https://www.youtube.com/embed/wK06wqISh_4"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={toggleLightbox}>
              <Carousel currentIndex={selectedIndex} views={images} />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </section>
  );
};

export default PersonalWebsitePage;
