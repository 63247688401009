import React, { useState } from "react";
import { Container, Hidden, Row, Col } from "react-grid-system";

const axios = require('axios');

const Contact = () => {
  const [email, setEmail] = useState("Submit");
  const [message, setMessage] = useState("");

  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
    sent: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();

    setData({
      ...data,
      buttonText: "Sending...",
    });

    axios
      .get("localhost:9000/.netlify/functions/api/sendmail", data)
      .then((res) => {
        if (res.data.result !== "success") {
          setData({
            ...data,
            buttonText: "Failed to Send",
            sent: false,
            err: "fail",
          });
          setTimeout(() => {
            resetForm();
          }, 6000);
        } else {
          setData({
            ...data,
            sent: true,
            buttonText: "Sent",
            err: "success",
          });
          setTimeout(() => {
            resetForm();
          }, 6000);
        }
      })
      .catch((err) => {
        setData({
          ...data,
          buttonText: "Failed to send",
          err: "fail",
        });
      });
  };

  const resetForm = () => {
    setData({
      name: "",
      email: "",
      message: "",
      sent: false,
      buttonText: "Send Message",
      err: "",
    });
  };

  return (
    <section className="section section-contact section-contact-1">
      <div className="display-spacing">
        <Container>
          <Row>
            <Col lg={7}>
              <div className="contact-content">
                <header className="el-heading">
                  <h2 className="el-icon">
                    <span className="el-icon-title">CONTACT ME</span>
                  </h2>
                  <h3>
                    <span>Get in touch with me today.</span>
                  </h3>
                  <div className="divider divider-1"></div>
                  <p>Hey you can get in contact with me through LinkedIn!</p>
                </header>
                {/* <form name="contact" method="GET" onSubmit={formSubmit} className="form form-1 pt-15">
                  <Row className="row">
                    <Col xs={12} sm={12} md={6}>
                      <div className="form-item">
                        <input
                          type="text"
                          id="form-item-name"
                          name="name"
                          onChange={handleChange}
                        />
                        <label htmlFor="form-item-name">Your Name</label>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                      <div className="form-item">
                        <input
                          type="email"
                          id="form-item-email"
                          name="email"
                          onChange={handleChange}
                        />
                        <label htmlFor="form-item-email">Your Email</label>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                      <div className="form-item">
                        <textarea
                          id="form-item-message"
                          name="message"
                          onChange={handleChange}
                        ></textarea>
                        <label htmlFor="form-item-message">Your Message</label>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                      <button className="button button-md button-primary">
                        Send Message
                      </button>
                    </Col>
                  </Row>
                </form> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default Contact;
