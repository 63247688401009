import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Switch, Route, BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
// Stylesheet
import "./index.scss";

// Components
import Error404 from "./components/common/Error404";

// const Root = () => {
//     return (

//         // <div>
//         //     <Switch>
//         //         <Route exact path="/" component={Demo} />
//         //         <Route path="/home-1" component={Home} />
//         //         <Route path="/home-2" component={Home2} />
//         //         <Route path="/home-3" component={Home3} />
//         //         <Route path="/home-4" component={Home4} />
//         //         <Route path="/home-5" component={Home5} />
//         //         <Route path="/home-6" component={Home6} />
//         //         <Route component={RedirectAs404} />
//         //     </Switch>
//         // </div>
//     );
// };

ReactDOM.render(
  // <BrowserRouter>
  //   <Route render={({ location }) => (location.state && location.state.is404 ? <Error404 /> : <App />)} />
  // </BrowserRouter>,
  // <BrowserRouter>
  //   <App />
  // </BrowserRouter>,
  <HashRouter>
    <Route
      render={({ location }) =>
        location.state && location.state.is404 ? <Error404 /> : <App />
      }
    />
  </HashRouter>,
  document.getElementById("root")
);
serviceWorker.unregister();
