import React, { useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Carousel, { Modal, ModalGateway } from "react-images";

// Components
import Headline from "../components/common/Headline";

const portfolio = [
  {
    name: "All",
    items: [
      {
        id: 0,
        title: "The Medici",
        link: "/portfolio-details",
        subtitle: "Night Photography",
        src: "/assets/images/photography/apartment_roof_resize.jpg",
        thumbnail: "/assets/images/photography/apartment_roof_resize.jpg",
      },
      {
        id: 1,
        title: "110",
        link: "/portfolio-details",
        subtitle: "Night Photography",
        src: "/assets/images/photography/apt_roof_resize.jpg",
        thumbnail: "/assets/images/photography/apt_roof_resize.jpg",
      },
      {
        id: 2,
        title: "Chicago",
        link: "/portfolio-details",
        subtitle: "City Photography",
        src: "/assets/images/photography/chicago_skyline_resize.jpg",
        thumbnail: "/assets/images/photography/chicago_skyline_resize.jpg",
      },
      {
        id: 3,
        title: "The Bean",
        subtitle: "City Photography",
        link: "/portfolio-details",
        src: "/assets/images/photography/the_bean_resize.jpg",
        thumbnail: "/assets/images/photography/the_bean_resize.jpg",
      },
      {
        id: 4,
        title: "Sora loves Nuggies",
        subtitle: "Portrait",
        link: "/portfolio-details",
        src: "/assets/images/photography/sora_nuggies_resize.jpg",
        thumbnail: "/assets/images/photography/sora_nuggies_resize.jpg",
      },
      {
        id: 5,
        title: "LA Overpass",
        subtitle: "Night Photography",
        link: "/portfolio-details",
        src: "/assets/images/photography/city_long_exposure_resize.jpg",
        thumbnail: "/assets/images/photography/city_long_exposure_resize.jpg",
      },
      {
        id: 6,
        title: "Date Night",
        subtitle: "Night Photography",
        link: "/portfolio-details",
        src: "/assets/images/photography/couple_water_bldg_resize.jpg",
        thumbnail: "/assets/images/photography/couple_water_bldg_resize.jpg",
      },
      {
        id: 7,
        title: "Vegas Vaporwave",
        subtitle: "Night Photography",
        link: "/portfolio-details",
        src: "/assets/images/photography/vegas_vapor_edit_resize.jpg",
        thumbnail: "/assets/images/photography/vegas_vapor_edit_resize.jpg",
      },
    ],
  },
  {
    name: "Night",
    items: [
      {
        id: 0,
        title: "The Medici",
        link: "/portfolio-details",
        subtitle: "Night Photography",
        src: "/assets/images/photography/apartment_roof_resize.jpg",
        thumbnail: "/assets/photography/apartment_roof_resize.jpg",
      },
      {
        id: 1,
        title: "110",
        link: "/portfolio-details",
        subtitle: "Night Photography",
        src: "/assets/images/photography/apt_roof_resize.jpg",
        thumbnail: "/assets/images/photography/apt_roof_resize.jpg",
      },
      {
        id: 5,
        title: "LA Overpass",
        subtitle: "Night Photography",
        link: "/portfolio-details",
        src: "/assets/images/photography/city_long_exposure_resize.jpg",
        thumbnail: "/assets/images/photography/city_long_exposure_resize.jpg",
      },
      {
        id: 6,
        title: "Date Night",
        subtitle: "Night Photography",
        link: "/portfolio-details",
        src: "/assets/images/photography/couple_water_bldg_resize.jpg",
        thumbnail: "/assets/images/photography/couple_water_bldg_resize.jpg",
      },
      {
        id: 7,
        title: "Vegas Vaporwave",
        subtitle: "Night Photography",
        link: "/portfolio-details",
        src: "/assets/images/photography/vegas_vapor_edit_resize.jpg",
        thumbnail: "/assets/images/photography/vegas_vapor_edit_resize.jpg",
      },
    ],
  },
  {
    name: "City",
    items: [
      {
        id: 2,
        title: "Chicago",
        link: "/portfolio-details",
        subtitle: "City Photography",
        src: "/assets/images/photography/chicago_skyline_resize.jpg",
        thumbnail: "/assets/images/photography/chicago_skyline_resize.jpg",
      },
      {
        id: 3,
        title: "The Bean",
        subtitle: "City Photography",
        link: "/portfolio-details",
        src: "/assets/images/photography/the_bean_resize.jpg",
        thumbnail: "/assets/images/photography/the_bean_resize.jpg",
      },
    ],
  },
  {
    name: "Portraits",
    items: [
      {
        id: 4,
        title: "Sora loves Nuggies",
        subtitle: "Portrait",
        link: "/portfolio-details",
        src: "/assets/images/photography/sora_nuggies_resize.jpg",
        thumbnail: "/assets/images/photography/sora_nuggies_resize.jpg",
      },
    ],
  },
];

const images = [
  {
    src: "/assets/images/photography/apartment_roof_resize.jpg",
  },
  {
    src: "/assets/images/photography/apt_roof_resize.jpg",
  },
  {
    src: "/assets/images/photography/chicago_skyline_resize.jpg",
  },
  {
    src: "/assets/images/photography/the_bean_resize.jpg",
  },
  {
    src: "/assets/images/photography/sora_nuggies_resize.jpg",
  },
  {
    src: "/assets/images/photography/city_long_exposure_resize.jpg",
  },
  {
    src: "/assets/images/photography/couple_water_bldg_resize.jpg",
  },
  {
    src: "/assets/images/photography/vegas_vapor_edit_resize.jpg",
  },
];

const PhotographyPage = () => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const toggleLightbox = (index) => {
    setSelectedIndex(index);
    setLightboxIsOpen(!lightboxIsOpen);
  };

  return (
    <section className="section section-portfolio section-portfolio-1">
      <div className="display-spacing">
        <Container className="container">
          <Headline
            label="Photography"
            title="Check out my photos"
            divider_1={true}
            position="center"
          />
          <p>
            Stephen gained his photography hobby in summer of 2019 where he likes to take Night Photos and City Scapes.  He Eventually wants to get into Astrophotography.
            All of these photos were taken with a Nikon D5600 and edited with Adobe Photoshop Express.
          </p>
          <Tabs className="el-tabs el-tabs-1" selectedTabClassName="active">
            <TabList className="el-tabs-links">
              {portfolio.map((category, categoryIndex) => (
                <Tab key={categoryIndex}>{category.name}</Tab>
              ))}
            </TabList>
            {portfolio.map((category, categoryIndex) => (
              <TabPanel key={categoryIndex}>
                <Row className="row-center mb--30">
                  {category.items.map((item, index) => (
                    <Col
                      key={index}
                      xs={6}
                      sm={6}
                      md={6}
                      lg={3}
                      xl={3}
                      onClick={() => toggleLightbox(item.id)}
                    >
                      <div className="portfolio-item">
                        <div className="portfolio-card">
                          <div
                            className="image overlay-image"
                            style={{ backgroundImage: `url(${item.src})` }}
                          />
                          <div className="content">
                            <button className="button button-circle button-icon button-circle-md button-primary">
                              <span className="el-icon-icon ti-fullscreen"></span>
                            </button>
                            <span>{item.title}</span>
                            <h3>{item.subtitle}</h3>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </TabPanel>
            ))}
          </Tabs>
        </Container>
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={toggleLightbox}>
              <Carousel currentIndex={selectedIndex} views={images} />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </section>
  );
};

export default PhotographyPage;
