import React, { useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Carousel, { Modal, ModalGateway } from "react-images";

// Components
import Headline from "../components/common/Headline";
import { useHistory } from "react-router-dom";

const portfolio = [
  {
    name: "All",
    items: [
      {
        id: 0,
        title: "Corgi Crew Coin",
        link: "/CorgiCrewCoin",
        subtitle: "Blockchain",
        src: "/assets/images/corgi/sora.png",
        thumbnail: "/assets/images/corgi/sora.png",
      },
      {
        id: 1,
        title: "Thyme Website",
        link: "/ThymeWebsite",
        subtitle: "Web Development",
        src: "/assets/images/projects/ThymeWebpage.png",
        thumbnail: "/assets/images/projects/ThymeWebpage.png",
      },
      {
        id: 2,
        title: "F-35: PHM",
        link: "/F35",
        subtitle: "Embedded Systems",
        src: "/assets/images/projects/F35.jpg",
        thumbnail: "/assets/images/projects/F35.jpg",
      },
      {
        id: 3,
        title: "Travel and Entertainment Search: Android",
        subtitle: "Mobile App Development",
        link: "/TravelEntertainmentAndroid",
        src: "/assets/images/projects/TravelEntertainmentAndroid.png",
        thumbnail: "/assets/images/projects/TravelEntertainmentAndroid.png",
      },
      {
        id: 4,
        title: "US Space Force Flying Sim 2019",
        subtitle: "Game Development",
        link: "/SpaceForceFlyingSim",
        src: "/assets/images/projects/SpaceForce.png",
        thumbnail: "/assets/images/projects/SpaceForce.png",
      },
      {
        id: 5,
        title: "Travel and Entertainment Search: Web Application",
        subtitle: "Web Development",
        link: "/TravelEntertainmentWeb",
        src: "/assets/images/projects/TravelEntertainmentWeb.jpg",
        thumbnail: "/assets/images/projects/TravelEntertainmentWeb.jpg",
      },
      {
        id: 6,
        title: "Bits Money",
        subtitle: "Product Strategy UX",
        link: "/BitsMoney",
        src: "/assets/images/projects/BitsMoney.jpg",
        thumbnail: "/assets/images/projects/BitsMoney.jpg",
      },
      {
        id: 7,
        title: "Mission Planning",
        subtitle: "Software Engineering",
        link: "/MissionPlanning",
        src: "/assets/images/projects/missionplanning.jpg",
        thumbnail: "/assets/images/projects/missionplanning.jpg",
      },
      {
        id: 8,
        title: "Personal Website",
        subtitle: "Web Development",
        link: "/PersonalWebsite",
        src: "/assets/images/projects/cropped.png",
        thumbnail: "/assets/images/projects/cropped.png",
      },
    ],
  },
  {
    name: "Blockchain",
    items: [
      {
        id: 0,
        title: "Corgi Crew Coin",
        link: "/CorgiCrewCoin",
        subtitle: "Blockchain",
        src: "/assets/images/corgi/sora.png",
        thumbnail: "/assets/images/corgi/sora.png",
      },
    ],
  },
  {
    name: "Web",
    items: [
      {
        id: 1,
        title: "Thyme Website",
        link: "/ThymeWebsite",
        subtitle: "Web Development",
        src: "/assets/images/projects/ThymeWebpage.png",
        thumbnail: "/assets/images/projects/ThymeWebpage.png",
      },
      {
        id: 5,
        title: "Travel and Entertainment Search: Web Application",
        subtitle: "Web Development",
        link: "/TravelEntertainmentWeb",
        src: "/assets/images/projects/TravelEntertainmentWeb.jpg",
        thumbnail: "/assets/images/projects/TravelEntertainmentWeb.jpg",
      },
    ],
  },
  {
    name: "Mobile",
    items: [
      {
        id: 3,
        title: "Travel and Entertainment Search: Android",
        subtitle: "Mobile App Development",
        link: "/TravelEntertainmentAndroid",
        src: "/assets/images/projects/TravelEntertainmentAndroid.png",
        thumbnail: "/assets/images/projects/TravelEntertainmentAndroid.png",
      },
    ],
  },
  {
    name: "Embedded",
    items: [
      {
        id: 2,
        title: "F-35: PHM",
        link: "/F35",
        subtitle: "Embedded Systems",
        src: "/assets/images/projects/F35solo.jpeg",
        thumbnail: "/assets/images/projects/F35solo.jpeg",
      },
    ],
  },
  {
    name: "UX",
    items: [
      {
        id: 6,
        title: "Bits Money",
        subtitle: "Product Strategy UX",
        link: "/BitsMoney",
        src: "/assets/images/projects/BitsMoney.jpg",
        thumbnail: "/assets/images/projects/BitsMoney.jpg",
      },
    ],
  },
];

const images = [
  {
    src: "/assets/images/photography/apartment_roof_resize.jpg",
  },
  {
    src: "/assets/images/photography/apt_roof_resize.jpg",
  },
  {
    src: "/assets/images/photography/chicago_skyline_resize.jpg",
  },
  {
    src: "/assets/images/photography/the_bean_resize.jpg",
  },
  {
    src: "/assets/images/photography/sora_nuggies_resize.jpg",
  },
  {
    src: "/assets/images/photography/city_long_exposure_resize.jpg",
  },
  {
    src: "/assets/images/photography/couple_water_bldg_resize.jpg",
  },
  {
    src: "/assets/images/photography/vegas_vapor_edit_resize.jpg",
  },
];

const ProjectsPage = () => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const toggleLightbox = (index) => {
    setSelectedIndex(index);
    setLightboxIsOpen(!lightboxIsOpen);
  };

  const history = useHistory();

  const handleRoute = (project_name) => {
    const page_str = "Projects" + project_name;
    history.push(page_str);
  };

  return (
    <section className="section section-portfolio section-portfolio-1">
      <div className="display-spacing">
        <Container className="container">
          <Headline
            label="Projects"
            title=""
            divider_1={true}
            position="center"
          />
          <Tabs className="el-tabs el-tabs-1" selectedTabClassName="active">
            <TabList className="el-tabs-links">
              {portfolio.map((category, categoryIndex) => (
                <Tab key={categoryIndex}>{category.name}</Tab>
              ))}
            </TabList>
            {portfolio.map((category, categoryIndex) => (
              <TabPanel key={categoryIndex}>
                <Row className="row-center mb--30">
                  {category.items.map((item, index) => (
                    <Col
                      key={index}
                      xs={6}
                      sm={6}
                      md={6}
                      lg={3}
                      xl={3}
                      onClick={() => handleRoute(item.link)}
                    >
                      <div className="portfolio-item">
                        <div className="portfolio-card">
                          <div
                            className="image overlay-image"
                            style={{ backgroundImage: `url(${item.src})` }}
                          />
                          <div className="content">
                            <button className="button button-circle button-icon button-circle-md button-primary">
                              <span className="el-icon-icon ti-fullscreen"></span>
                            </button>
                            <span>{item.title}</span>
                            <h3>{item.subtitle}</h3>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </TabPanel>
            ))}
          </Tabs>
        </Container>
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={toggleLightbox}>
              <Carousel currentIndex={selectedIndex} views={images} />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </section>
  );
};

export default ProjectsPage;
