import React, { useState } from "react";
import { Container, Hidden, Row, Col } from "react-grid-system";


const AboutMePage = () => {

  const images = [
    {
      src: "/assets/images/about/Bodybuilding.jpg",
    },
    {
      src: "/assets/images/photography/apt_roof_resize.jpg",
    },
    {
      src: "/assets/images/photography/chicago_skyline_resize.jpg",
    },
    {
      src: "/assets/images/photography/the_bean_resize.jpg",
    },
    {
      src: "/assets/images/photography/sora_nuggies_resize.jpg",
    },
    {
      src: "/assets/images/photography/city_long_exposure_resize.jpg",
    },
    {
      src: "/assets/images/photography/couple_water_bldg_resize.jpg",
    },
    {
      src: "/assets/images/photography/vegas_vapor_edit_resize.jpg",
    },
  ];

  let sliderSettings = {
    dots: true,
    speed: 500,
    arrows: true,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const toggleLightbox = (index) => {
    setSelectedIndex(index);
    setLightboxIsOpen(!lightboxIsOpen);
  };
  return (
    <div>
      <section className="section section-about section-about-1">
        <div className="display-spacing">
          <Container>
            <Row>
              <Col lg={6}>
                <div className="about-content">
                  <header className="el-heading">
                    <h2 className="el-icon">
                      <span className="el-icon-title">ABOUT ME</span>
                    </h2>
                    <h3>
                      <span>Hello, I am Stephen Koy</span>
                    </h3>
                    <div className="divider divider-1"></div>
                    <p>
                      Stephen is a Chicago native living in Los Angeles, that
                      works as a software engineer on the F-35 Prognostics
                      Health Management system in a real-time operating system
                      environment at Northrop Grumman. He currently holds a
                      Master's of Computer Science degree from the University of
                      Southern California. He graduated Magna Cum Laude from
                      Weber State University with his Bachelor's of Computer
                      Science and a minor in Mathematics.
                    </p>
                    <p>
                      Stephen is a Nationally qualified Men's Phyisque
                      competitor so he likes to stay active. He enjoys
                      weightlifting, martial arts, snowboarding and
                      skateboarding. Stephen also enjoys going to live music
                      events.
                    </p>
                    <p>
                      When not out and about, Stephen likes to read, learn new
                      things, act in scene study class, trade stocks and crypto,
                      shoot photos with his Nikon and work on personal projects
                      that that allows him to fulfill of passion for building
                      things such as his Corgi Coin Blockchain project.
                    </p>
                  </header>
                </div>
              </Col>
              <Hidden xs sm md>
                <Col lg={6}>
                  <div className="about-image">
                    <div
                      className="image overlay-image"
                      style={{
                        backgroundImage: `url(/assets/images/about/main_pic.jpg)`,
                      }}
                    ></div>
                  </div>
                </Col>
              </Hidden>
            </Row>
            <Row className="pt-30">
              <Col xs={12} sm={12} md={6}>
                <div className="el-card-icon">
                  <div className="el-icon">
                    <span className="el-icon-icon ti-light-bulb"></span>
                  </div>
                  <div className="content">
                    <h5 style={{ marginTop: "16px" }}>Software Engineering</h5>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <div className="el-card-icon">
                  <div className="el-icon">
                    <span className="el-icon-icon ti-image"></span>
                  </div>
                  <div className="content">
                    <h5 style={{ marginTop: "16px" }}>
                      Blockchain Development
                    </h5>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <div className="el-card-icon">
                  <div className="el-icon">
                    <span className="el-icon-icon ti-paint-bucket"></span>
                  </div>
                  <div className="content">
                    <h5 style={{ marginTop: "16px" }}>Web Development</h5>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <div className="el-card-icon">
                  <div className="el-icon">
                    <span className="el-icon-icon ti-mobile"></span>
                  </div>
                  <div className="content">
                    <h5 style={{ marginTop: "16px" }}>Mobile Apps</h5>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <div className="el-card-icon">
                  <div className="el-icon">
                    <span className="el-icon-icon ti-ruler-pencil"></span>
                  </div>
                  <div className="content">
                    <h5 style={{ marginTop: "16px" }}>UI/UX Designer</h5>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <div className="el-card-icon">
                  <div className="el-icon">
                    <span className="el-icon-icon ti-cup"></span>
                  </div>
                  <div className="content">
                    <h5 style={{ marginTop: "16px" }}>Personal Training</h5>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </div>
  );
};

export default AboutMePage;
