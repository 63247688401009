import React, { useState } from "react";

import { FaCheck, FaGithub, IconName } from "react-icons/fa";
import { Container, Row, Col } from "react-grid-system";
import Carousel, { Modal, ModalGateway } from "react-images";
// Components
import GoBack from "../../components/common/GoBack";
//import main_img from "../../../public/assets/images/projects/ThymeWebpage.png";

const images = [
  {
    src: "/assets/images/projects/ThymeWebpage.png",
  },
];

const PersonalWebsitePage = () => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const toggleLightbox = (index) => {
    setSelectedIndex(index);
    setLightboxIsOpen(!lightboxIsOpen);
  };

  return (
    <section className="section section-single-post section-single-post-1">
      <div className="display-spacing">
        <Container>
          <Row>
            <Col lg={8}>
              <div className="max-w-768 div-center">
                <div className="post">
                  <div className="post-head">
                    <div class="post-info">
                      <GoBack />
                      <h1>Personal Website</h1>
                    </div>
                  </div>
                  {/* Need to add button to Github
                Write a function to only show the button if there is a github linked provided */}
                  {/* <div>
                    <button
                      onClick={() =>
                        window.open(
                          "https://www.github.com/StephenKoy/Thyme-Webpage",
                          "_blank"
                        )
                      }
                    >
                      <FaGithub className="icon" />
                    </button>
                  </div> */}
                  <div className="post-body">
                    <div className="post-content">
                      <p>
                        <b>Technologies:</b>
                      </p>
                      <ul class="el-list-check">
                        <li>
                          {/* <FaCheck className="icon" /> */}
                          ReactJS, NodeJS, SCSS, HTML5
                        </li>
                      </ul>
                      <p>
                        Personal webpage made using ReactJS and NodeJS
                        Technologies to showcase some of my projects and photos.  
                        I chose to use ReactJS for its smooth page routing and because I wanted to learn a new framework and make myself more marketable.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={7}>
              <Row>
                <Col style={{ marginTop: "50px", marginLeft: "20px" }}>
                  {/* <iframe
                    width="600px"
                    height="360px"
                    src="https://www.youtube.com/embed/ziS73p9g4AE"
                  /> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={toggleLightbox}>
              <Carousel currentIndex={selectedIndex} views={images} />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </section>
  );
};

export default PersonalWebsitePage;
